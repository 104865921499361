<template>
	<div class="row-space-tbf" v-if="loaded">
		<div class="space-left"><div v-if="$resize && $mq.above(780)" class="go-back" @click="$router.push({name: 'roles'})"><icon-arrow-back /></div></div>
		<div class="content opacity-page">
			<div class="header-form">
				<h1 class="title">{{role.id ? $t('general.update') : $t('roles.create-new-role') }}</h1>
				<div class="actions">
					<button class="btn-tbf white only-icon" @click="$router.push({name: 'roles'})"><icon-close class="icon-close" /></button>
				</div>
			</div>

			<div class="form-group">
				<div class="data-group">
					<div class="title">{{$t('roles.role')}}</div>
					<div class="description">{{$t('roles.details')}}</div>
				</div>
				<div class="form">
					<div class="input-group w-100">
						<div class="label-header">
							<label class="label">{{$t('general.name')}}*</label>
							<div v-if="$v.role.name.$error" class="error-msg">{{ $t('validator.required') }}</div>
							<div v-if="errorsBe.name" class="error-msg">{{ errorsBe.name.join(" | ") }}</div>
						</div>
						<div class="input-box" v-bind:class="{has_error: $v.role.name.$error}">
							<div class="icon-left"><icon-role /></div>
							<div class="icon-right" v-if="role.name" @click.stop="role.name = ''"><icon-close class="icon-clear" /></div>
							<input type="text" :placeholder="$t('roles.name-ph')" class="input-text" v-model="role.name">
						</div>
					</div>
					<div class="input-group w-100">
						<div class="label-header">
							<label class="label">{{$t('general.description')}}</label>
						</div>
						<div class="input-box">
							<textarea :placeholder="$t('roles.description-ph')" class="input-text" v-model="role.description"></textarea>
						</div>
					</div>
				</div>
			</div>

			<div class="form-submit">
				<div class="error-msg" v-show="error_message != ''">{{error_message}}</div>
				<button class="btn-tbf grey" @click="$router.push({name: 'roles'})"><span class="text">{{ $t('general.cancel') }}</span></button>
				<button id="buttonCreate" class="btn-tbf blue" @click="role.id ? saveAction('update') : saveAction('create')">
					<div class="loader"></div>
					<span class="text">{{ role.id ? $t('general.update') : $t('general.add')}}</span>
				</button>
			</div>
		</div>
		<div class="space-right"></div>
	</div>
	<loader-users-create  v-else/>
</template>

<script>
	import IconArrowBack from '../../Icons/ArrowLong'
	import IconClose from '../../Icons/Close'
	import IconRole from '../../Icons/Role'
	import LoaderUsersCreate from '../../PagesLoaders/UsersCreate'
	import { required } from 'vuelidate/lib/validators'

	export default {
		components: {
			IconArrowBack,
			IconClose,
			IconRole,
			LoaderUsersCreate,
		},
		data() {
			return {
				loaded: false,
				role: {
					name: '',
					description: ''
				},
				error_message: '',
				errorsBe: {}
			};
		},
		async mounted(){
			if(!this.$auth.user().rights.admins_instance_crud){
				this.$router.push({name: 'forbbiden'})
			}
			
			if(this.$route.params.id){
				await this.getRoleData()
			}else{
				setTimeout(() => {
					this.loaded = true
					setTimeout(() => {
						$('.opacity-page').addClass('show')
					}, 0)
				}, 0)
			}
		},
		validations: {
			role: {
				name: {required}
			}
		},
		methods: {
			async getRoleData(){
				await axios.get(`/roles/${this.$route.params.id}/edit`)
				.then(({data}) => {
					this.role = data.data
				}).catch(error => {
					if(error.response){
						if(error.response.status == 403) {
							this.$router.push({name: 'forbbiden'})
						}else if(error.response.status == 404) {
							this.$router.push({name: 'not-found'})
						} else if(error.response.status == 500) {
							alert(this.$t('error.500'))
						}
					}
				})
				.finally(() => {
					setTimeout(() => {
						this.loaded = true
						setTimeout(() => {
							$('.opacity-page').addClass('show')
						}, 0)
					}, 0)
				})
			},
			saveAction(type){
				var buttonName = `buttonCreate`
				var btnSubmit = document.getElementById(buttonName);
				var btnSubmitLoader = document.querySelector(`#${buttonName} .loader`)
				var btnSubmitText = document.querySelector(`#${buttonName} .text`)
				
				btnSubmit.disabled = true
				btnSubmit.classList.add('loading')
				btnSubmitLoader.classList.add('onProgress')
				btnSubmitText.innerHTML = this.$t('btn-submit.loading')

				this.error_message = '';
				this.$v.$touch()

				if(!this.$v.$invalid){
					let objData = {
						name: this.role.name,
						description: this.role.description
					}

					if(type == 'create'){
						this.createRole(objData, btnSubmit, btnSubmitText, btnSubmitLoader)
					}else if(type == 'update'){
						this.updateRole(objData, btnSubmit, btnSubmitText, btnSubmitLoader)
					}
				}else{
					btnSubmitLoader.classList.add('finish')
					setTimeout(()=>{
						btnSubmitText.innerHTML = this.$t('btn-submit.error')
						btnSubmit.classList.add('error')
						btnSubmitLoader.classList.remove('onProgress', 'finish')
						btnSubmit.classList.remove('loading')
						setTimeout(()=>{
							btnSubmit.classList.remove('error')
							btnSubmitText.innerHTML = type == 'create' ? this.$t('general.add') : this.$t('general.update')
							btnSubmit.disabled = false
						}, 1000)
					}, 300)
				}
			},
			createRole(objData, btnSubmit, btnSubmitText, btnSubmitLoader){
				axios.post('/roles/store', objData)
				.then(({data}) => {
					btnSubmitLoader.classList.add('finish')
					setTimeout(()=>{
						btnSubmitText.innerHTML = this.$t('btn-submit.success')
						btnSubmit.classList.add('completed')
						btnSubmitLoader.classList.remove('onProgress', 'finish')
						btnSubmit.classList.remove('loading')
						setTimeout(()=>{
							this.$router.push({name: 'roles-show', params: {id: data.data.id}})
						}, 500)
					}, 300)
				}).catch(error => {
					btnSubmitLoader.classList.add('finish')
					this.errorsBe = error.response.data.errors
					setTimeout(()=>{
						btnSubmitText.innerHTML = this.$t('btn-submit.error')
						btnSubmit.classList.add('error')
						btnSubmitLoader.classList.remove('onProgress', 'finish')
						btnSubmit.classList.remove('loading')
						setTimeout(()=>{
							btnSubmit.classList.remove('error')
							btnSubmitText.innerHTML = this.$t('general.add')
							btnSubmit.disabled = false
							if(error.response.status == 500){
								this.error_message = this.$t('error.500')
							}
						}, 1000)
					}, 300)
				})
			},
			updateRole(objData, btnSubmit, btnSubmitText, btnSubmitLoader){
				axios.patch(`/roles/${this.role.id}`, objData)
				.then(({data}) => {
					btnSubmitLoader.classList.add('finish')
					setTimeout(()=>{
						btnSubmitText.innerHTML = this.$t('btn-submit.success')
						btnSubmit.classList.add('completed')
						btnSubmitLoader.classList.remove('onProgress', 'finish')
						btnSubmit.classList.remove('loading')
						setTimeout(()=>{
							this.$router.push({name: 'roles-show', params: {id: data.data.id}})
						}, 500)
					}, 300)
				})
				.catch(error => {
					btnSubmitLoader.classList.add('finish')
					this.errorsBe = error.response.data.errors
					setTimeout(()=>{
						btnSubmitText.innerHTML = this.$t('btn-submit.error')
						btnSubmit.classList.add('error')
						btnSubmitLoader.classList.remove('onProgress', 'finish')
						btnSubmit.classList.remove('loading')
						setTimeout(()=>{
							btnSubmit.classList.remove('error')
							btnSubmitText.innerHTML = this.$t('general.update')
							btnSubmit.disabled = false
							if(error.response.status == 500){
								this.error_message = this.$t('error.500')
							}
						}, 1000)
					}, 300)
				})
				.finally(() => {
					setTimeout(()=>{
						btnSubmit.disabled = false
					}, 1500)
				})
			}
		}
	};
</script>